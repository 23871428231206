@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@400;700&family=Roboto+Condensed:wght@400;700&display=swap");

* {
  box-sizing: border-box;
}

html {
  font-family: "Quicksand", sans-serif;
  line-height: 1.5;

  color: #ebe7ef;
  background: radial-gradient(#280a48, #20043d);

  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
  min-height: 80rem;
}

main {
  width: 90%;
  max-width: 50rem;
  margin: auto;
}

main h2 {
  text-align: center;
  font-family: "Roboto Condensed", sans-serif;
  margin: 0 0 1.5rem 0;
  color: #a18aba;
}

