#services {
    padding: 2rem;
    border-radius: 6px;
    background-color: #140524;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.4);
}

#services ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    gap: 2rem;
    flex-wrap: wrap;
    justify-content: center;
}

#services li {
    width: 10rem;
    text-align: center;
}

#services li img {
    height: 4rem;
    width: 6rem;
    object-fit: cover;
}

#services li h3 {
    margin: 0.5rem 0;
}

#services li p {
    font-size: 0.9rem;
}