@import url(https://fonts.googleapis.com/css2?family=Quicksand:wght@400;700&family=Roboto+Condensed:wght@400;700&display=swap);
header {
  text-align: center;
  margin: 3rem 0;
}

header img {
  height: 30rem;
  width: 60rem;
  object-fit: cover;
}

header h1 {
  margin: 0;
  font-family: "Roboto Condensed", sans-serif;
  font-size: 5rem;
  background: linear-gradient(40deg, #ea00ff, #ea00ff, #03d5ff, #03d5ff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-filter: drop-shadow(0 2px 8px rgba(0, 0, 0, 0.5));
          filter: drop-shadow(0 2px 8px rgba(0, 0, 0, 0.5));
}

header p {
  margin: 0;
  font-size: 1.25rem;
  color: #8964b0;
  font-family: "Roboto Condensed", sans-serif;
}
#services {
    padding: 2rem;
    border-radius: 6px;
    background-color: #140524;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.4);
}

#services ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    grid-gap: 2rem;
    gap: 2rem;
    flex-wrap: wrap;
    justify-content: center;
}

#services li {
    width: 10rem;
    text-align: center;
}

#services li img {
    height: 4rem;
    width: 6rem;
    object-fit: cover;
}

#services li h3 {
    margin: 0.5rem 0;
}

#services li p {
    font-size: 0.9rem;
}
* {
  box-sizing: border-box;
}

html {
  font-family: "Quicksand", sans-serif;
  line-height: 1.5;

  color: #ebe7ef;
  background: radial-gradient(#280a48, #20043d);

  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
  min-height: 80rem;
}

main {
  width: 90%;
  max-width: 50rem;
  margin: auto;
}

main h2 {
  text-align: center;
  font-family: "Roboto Condensed", sans-serif;
  margin: 0 0 1.5rem 0;
  color: #a18aba;
}


