header {
  text-align: center;
  margin: 3rem 0;
}

header img {
  height: 30rem;
  width: 60rem;
  object-fit: cover;
}

header h1 {
  margin: 0;
  font-family: "Roboto Condensed", sans-serif;
  font-size: 5rem;
  background: linear-gradient(40deg, #ea00ff, #ea00ff, #03d5ff, #03d5ff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  filter: drop-shadow(0 2px 8px rgba(0, 0, 0, 0.5));
}

header p {
  margin: 0;
  font-size: 1.25rem;
  color: #8964b0;
  font-family: "Roboto Condensed", sans-serif;
}